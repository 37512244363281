import { invert } from 'lodash-es';

export const DELIVERY_TYPE = {
  delivery: 1,
  pickupFromLocation: 2,
};

export const PAYMENTS_METHODS = {
  accountBallance: 1,
  cashOnDelivery: 2,
  crypto: 3,
  bankTransfer: 4,
  cashWallet: 5,
  oneWallet: 6,
  oespWallet: 7,
  usdt: 8,
};

export const PAYMENTS_METHODS_INVERSE = invert(PAYMENTS_METHODS);
