import { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { isArray, isFunction, pick } from '@veraio/core';
import { Button, Icon, AddressModalForm } from 'components';
import { addressCartContainer, addressCart } from './styles';

const AddressCart = ({ addresses = [], active, withAdd, onChange, onAddAddress, className }) => {
  const { getText, getDynamicTranslation } = useTranslations();
  const addressFormRef = useRef();

  const handleCloseModal = () => addressFormRef.current?.close();

  return (
    <>
      <div css={addressCartContainer} {...(className && { className })}>
        {withAdd && (
          <Button small type="link" onClick={() => addressFormRef.current?.open()} className="add-button">
            <Icon iconName="add" size={30} />
            <p>{getText('addNewAddress')}</p>
          </Button>
        )}

        {addresses?.map((address, idx) => (
          <Button
            key={idx}
            type="default"
            css={addressCart(address?.id === active)}
            className="cart"
            onClick={() => isFunction(onChange) && onChange(address.id)}>
            <h3>{isArray(address?.name) ? getDynamicTranslation(address?.name)?.name : address?.name}</h3>
            <p>{address?.street}</p>
            <p>
              {Object.values(pick(address, ['city', 'state', 'postcode', 'country']))
                .filter(Boolean)
                .join(', ')}
            </p>
            <p>{`+${address?.phoneNumber}`}</p>
          </Button>
        ))}
      </div>

      <AddressModalForm ref={addressFormRef} onClose={handleCloseModal} onAddAddress={onAddAddress} />
    </>
  );
};

AddressCart.propTypes = {
  withAdd: PropTypes.bool,
  addresses: PropTypes.array,
  active: PropTypes.any,
  onChange: PropTypes.func,
  onAddAddress: PropTypes.func,
  className: PropTypes.string,
};

export default AddressCart;
