import { useEffect } from 'react';
import { startCase } from 'lodash-es';
import { getText } from '@veraio/strank';
import { Button, Col, Image, Row, BecomeAffiliateCart } from 'components';
import { ROUTES } from 'enums';
import { liposomesData, liposomalIngredientsData } from './config';
import { ingredientsContainer } from './styles';

const Ingredients = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div css={ingredientsContainer}>
      <Image
        src="https://oneecosystem.blob.core.windows.net/vita/apps/images/ingredients-header-image.jpg"
        alt="header-image"
        className="header-image"
      />

      <div className="header-content-container">
        <h3 className="header-title">{startCase(getText('whereScienceMeetsBeauty'))}</h3>
        <p className="header-description">{getText('experienceTransformativePowerOfLiposomes')}</p>
      </div>

      <div className="ingredients-content">
        <h3 className="title">{getText('liposomes')}</h3>

        <div className="liposomes-container">
          <Image
            src="https://oneecosystem.blob.core.windows.net/vita/apps/images/liposomes-structure-image.jpg"
            alt="liposome-image"
            className="liposomes-image"
          />

          <div className="liposomes-content-container">
            <p className="liposomes-title">{getText('harnessPowerOfThisRevolutionaryTechnology')}</p>
            <p className="liposomes-description">{getText('theseMicroSizedMoleculesRepresent')}</p>
          </div>
        </div>

        <Row gap={24} className="liposome-future-container">
          {liposomesData.map((el, ind) => (
            <Col key={ind} xs={12} sm={12} md={6} lg={6} xl={6}>
              <Image src={el.image} alt="liposome-future-image" className="liposome-future-image" />
              <h4 className="liposome-future-title">{getText(el.title)}</h4>
              <p className="liposome-future-description">{getText(el.description)}</p>
            </Col>
          ))}
        </Row>
      </div>

      <div className="other-ingredients-container">
        <h3 className="other-title">{getText('liposomalIngredients')}</h3>

        <Row gap={12}>
          {liposomalIngredientsData.map((el, ind) => (
            <Col key={ind} xs={12} sm={12} md={6} lg={4} xl={4}>
              <div className="other-ingredients-cart">
                <Image src={el.image} alt="other-ingredients-image" className="other-ingredients-image" />
                <h4 className="other-ingredients-title">{getText(el.title)}</h4>
                <p className="other-ingredients-description">{getText(el.description)}</p>
              </div>
            </Col>
          ))}
        </Row>
      </div>

      <div className="discover-container">
        <h3 className="discover-beauty-title">{startCase(getText('discoverTimelessBeauty'))}</h3>
        <p className="discover-beauty-subtitle">{getText('unveilSecretOfLiposomesAndZeolites')}</p>
        <Button type="secondary" className="action-button" linkTo={ROUTES.Products}>
          {getText('exploreProducts')}
        </Button>
      </div>

      <Image
        src="https://oneecosystem.blob.core.windows.net/vita/apps/images/all-products-masks-valentines.jpg"
        alt="ingredients-all-products"
        className="discover-image"
      />
      <BecomeAffiliateCart />
    </div>
  );
};

export default Ingredients;
