import { theme } from 'styles';

export const errorContent = {
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: '1em',
  color: theme.error,

  '.error-icon': {
    marginRight: 4,
    width: 'auto',
    height: '1rem',
    maxHeight: '100%',
  },
};

export const shoppingCartContainer = {
  margin: '4em 0',
  padding: '0 6.25em',
  position: 'relative',

  '.header-title': {
    fontWeight: 500,
    padding: '2em 0',
    color: theme.gray900,
  },

  '.address-cart-container': {
    marginBottom: '2em',
  },

  '.subtitle': {
    marginBottom: 16,
    fontWeight: 500,
  },

  '.shopping-cart-info-container': {
    width: '100%',

    '.items-container': {
      height: '100%',

      '.mini-cart-items-container': {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',

        '& > div': {
          '&:last-of-type': {
            marginBottom: 0,
          },
        },
      },
    },

    '.accounts-dropdown': {
      '.input-label': {
        fontWeight: 500,
        fontSize: '1.2em',
      },
    },

    '.horizontal-line': {
      backgroundColor: theme.gray200,
      margin: '2em 0 3em',
    },

    '.confirm-button': {
      width: '100%',

      '.icon': {
        marginRight: '0.5em',
      },
    },

    '.wallets-container': {
      margin: '1em 0',

      '.title': {
        marginBottom: 16,
      },

      '.subtitle': {
        fontSize: '1.2em',
        fontWeight: 500,
      },

      '.wallet-cart-container': {
        display: 'flex',
        flexDirection: 'row',

        '.wallet-cart': {
          marginRight: 16,

          '&:last-child': {
            marginRight: 0,

            '@media (max-width: 576px)': {
              marginBottom: 0,
              width: '100%',
            },
          },

          '@media (max-width: 567px)': {
            marginRight: 0,
            marginBottom: 16,
            width: '100%',
          },
        },

        '@media (max-width: 567px)': {
          flexDirection: 'column',
          width: '100%',
        },
      },
    },
  },

  '@media (max-width: 567px)': {
    padding: '0 2em',
  },
};
