import { invert } from 'lodash-es';

export const ORDER_STATUS = {
  new: 1,
  completed: 4,
  declined: 6,
  canceled: 5,
  paymentFailed: 7,
  partiallyPaid: 8,
};

export const ORDER_STATUS_INVERSE = invert(ORDER_STATUS);

export const ORDER_STATUS_COLOR = {
  [ORDER_STATUS.new]: {
    color: 'info',
  },
  [ORDER_STATUS.completed]: {
    color: 'success',
  },
  [ORDER_STATUS.declined]: {
    color: 'red',
  },
  [ORDER_STATUS.canceled]: {
    color: 'error',
  },
  [ORDER_STATUS.paymentFailed]: {
    color: 'red',
  },
  [ORDER_STATUS.partiallyPaid]: {
    color: 'warning',
  },
};
