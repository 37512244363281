import { theme } from 'styles';

export const addressCartContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',

  '.add-button': {
    color: theme.gray900,
    fontSize: '1.2rem',
    lineHeight: 1.5,
    border: `1px solid ${theme.black24}`,
    backgroundColor: 'transparent',
    borderRadius: 8,
    margin: '0 0.75em 0.75em 0',
    padding: '1.5em',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: 'column',
    fontWeight: 600,
    width: 180,

    '@media (max-width: 767px)': {
      width: '100%',
    },

    '&:hover': {
      cursor: 'pointer',
      border: `1px solid ${theme.infoLight}`,
      backgroundColor: theme.gray100,
      color: theme.gray900,
    },
  },
};

export const addressCart = (active) => ({
  color: theme.gray900,
  fontSize: '1.2rem',
  lineHeight: 1.5,
  fontWeight: 400,
  border: `1px solid ${active ? theme.pink : theme.black24}`,
  backgroundColor: active ? theme.infoLight : 'transparent',
  borderRadius: 8,
  padding: '1em',
  margin: '0 0.75em 0.75em 0',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  flexDirection: 'column',
  whiteSpace: 'normal',
  width: 180,

  '@media (max-width: 767px)': {
    width: '100%',
  },

  '& h3': {
    fontSize: '1.2rem',
    marginBottom: '0.25em',
  },

  '&:hover': {
    cursor: 'pointer',
    border: `1px solid ${active ? 'none' : theme.infoLight}`,
    backgroundColor: active ? theme.infoLight : 'transparent',
    color: theme.gray900,
  },
});
