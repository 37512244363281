import { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from '@veraio/core';
import { Icon, Row, Col } from 'components';
import { counterInputContainer, icon } from './styles';

const CounterInput = forwardRef((props, ref) => {
  const { value, onChange, isOutOfRange, className } = props;
  const [newValue, setNewValue] = useState(value ?? 1);
  const isDisabled = newValue === 1 ?? value === 1;

  useImperativeHandle(ref, () => ({
    value: newValue,
  }));

  const handleRemoveNumber = (e) => {
    if (isDisabled) return e.preventDefault();

    setNewValue((prev) => prev - 1);
    isFunction(onChange) && onChange(newValue - 1);
  };

  const handleAddNumber = () => {
    !isOutOfRange && setNewValue((prev) => prev + 1);
    isFunction(onChange) && onChange(newValue + 1);
  };

  return (
    <Row css={counterInputContainer(props)} {...(className && { className })}>
      <Col xs={3} sm={3} md={4} lg={4}>
        <Icon material iconName="do_not_disturb_on" onClick={handleRemoveNumber} size={24} css={icon(isDisabled)} />
      </Col>
      <Col xs={6} sm={6} md={4} lg={4} displayFlex align="center" justify="center">
        <p className="number">{newValue}</p>
      </Col>
      <Col xs={3} sm={3} md={4} lg={4}>
        <Icon material iconName="add_circle" onClick={handleAddNumber} size={24} css={icon(isOutOfRange)} />
      </Col>
    </Row>
  );
});

CounterInput.propTypes = {
  value: PropTypes.number,
  isOutOfRange: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default CounterInput;
