import { calculateDealPrice } from '@oneecosystem/dealshaker-core';
import { getReq } from 'services/axios';
import { getAllMerchantDealsParams } from 'services';

const baseUrl = `${apiUrls.dealshakerPortalApiUrl}/api/Deals`;

export const getProductDetails = async (dealName) => {
  const [res, err] = await getReq(`${baseUrl}/Details/${dealName}`);
  return [res && calculateDealPrice(res), err];
};

export const getAllMerchantDeals = (options) =>
  getReq(`${baseUrl}/GetMerchantProfileDeals?${getAllMerchantDealsParams(options)}`);
