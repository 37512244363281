import { getToken } from '@oneecosystem/authenticate';
import { useStore, createStore, showApiError } from 'components';
import { getDealShakerProfile, getOneLifeProfile } from 'services';

const userStore = createStore({
  isAuthenticated: !!getToken(),
  userLifeInfo: null,
  userShakerInfo: null,
  accounts: [],
  affiliates: {
    status: 'Pending',
    createdDate: '2024-06-10T07:31:15.339996Z',
    modifiedDate: '2024-06-10T07:31:15.339996Z',
  },
});

export const initializeUser = async () => {
  const [lifeUser, lifeUserErr] = await getOneLifeProfile();
  if (lifeUserErr) return showApiError(lifeUserErr);

  const [shakerUser, shakerUserErr] = await getDealShakerProfile();
  if (shakerUserErr) return showApiError(shakerUserErr);

  userStore.setState({
    userLifeInfo: lifeUser,
    userShakerInfo: shakerUser,
    accounts: lifeUser?.accounts?.map((el) => ({ ...el, displayName: `#${el.id} ${el.nickName}` })),
    isAuthenticated: true,
    affiliates: {
      status: 'Approved',
      createdDate: '2024-06-10T07:31:15.339996Z',
      modifiedDate: '2024-06-10T07:31:15.339996Z',
    },
  });
};

export const useUserStore = (...args) => useStore(userStore, ...args);
