import { useEffect, useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { getStorageValue } from '@veraio/core';
import { NotFound } from 'screens/public';
import { Col, MiniShoppingCartItem, Row, showApiError, TotalPricesCard, useWindowSize } from 'components';
import { useUserStore } from 'stores';
import { getOrdersDetails } from 'services';
import { ORDER_INFO, USER_DELIVERY_INFO, USER_INFO } from './config';
import { shoppingCartSummeryContainer } from './styles';

const storageKey = 'shopping-cart-summery';

const ShoppingCartSummery = () => {
  const user = useUserStore();
  const { getText } = useTranslations();
  const windowWidth = useWindowSize(window.innerWidth, window.innerHeight);
  const summeryData = getStorageValue(storageKey);
  const [orderDetails, setOrderDetails] = useState({});

  const items = summeryData?.businesses?.flatMap((business) => business.items);
  const deliveryAddress = user?.userShakerInfo?.userAddresses?.find((el) => el.id === summeryData?.deliveryAddress);
  const shakerAccount = user?.userShakerInfo?.accounts?.find((el) => el.externalId === summeryData?.accountId);
  const lifeAccount = user?.accounts?.find((el) => el.id === summeryData?.accountId);
  const accountInfo = { ...lifeAccount, ...shakerAccount };
  const isMobile = windowWidth.width < 767;

  useEffect(() => {
    window.scrollTo(0, 0);
    summeryData?.orderId && fetchOrderDetails();
  }, []);

  const fetchOrderDetails = async () => {
    const [res, err] = await getOrdersDetails(summeryData?.orderId);
    err ? showApiError(err) : setOrderDetails(res[0]);
  };

  return (
    <div css={shoppingCartSummeryContainer}>
      <h3 className="header-title">{getText('shoppingCartSummery')}</h3>

      {summeryData?.orderId ? (
        <Row gap={isMobile ? 4 : 32} className="shopping-cart-summery-info-container">
          <Col sm={12} md={12} lg={6}>
            <h4 className="subtitle">{getText('products')}</h4>

            <div className="items-container">
              <div className="mini-cart-items-container">
                {items?.map((product) => (
                  <MiniShoppingCartItem key={product.dealId ?? product.id} product={product} />
                ))}
              </div>
            </div>
          </Col>

          <Col sm={12} md={12} lg={6}>
            <div className="user-delivery-container">
              <div className="address-cart-container">
                <h4 className="subtitle">{getText('orderDetails')}</h4>
                <div className="order-cart">
                  {ORDER_INFO(orderDetails)?.map((el, ind) => (
                    <p className="info" key={ind}>
                      <b className="bold">{getText(el.label)}: </b>
                      {el.value}
                    </p>
                  ))}
                </div>

                <h4 className="subtitle">{getText('deliveryDetails')}</h4>
                <div className="order-cart">
                  {USER_DELIVERY_INFO(deliveryAddress)?.map((el, ind) => (
                    <p className="info" key={ind}>
                      <b className="bold">{getText(el.label)}: </b>
                      {el.value}
                    </p>
                  ))}
                </div>
              </div>

              <div>
                <h4 className="subtitle">{getText('userInfo')}</h4>
                {USER_INFO(user?.userLifeInfo, accountInfo)?.map((el, ind) => (
                  <p className="info" key={ind}>
                    <b className="bold">{getText(el.label)}: </b>
                    {el.value}
                  </p>
                ))}
              </div>
            </div>

            <hr className="horizontal-line" />

            <h4 className="subtitle">{getText('priceDetails')}</h4>
            <TotalPricesCard shoppingCart={summeryData} isSummery />
          </Col>
        </Row>
      ) : (
        <NotFound />
      )}
    </div>
  );
};

export default ShoppingCartSummery;
