import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Button, CounterInput, Icon, Image, ProductPrice } from 'components';
import { removeProduct, updateProductQuantity, useUserStore } from 'stores';
import { cartItemContainer } from './styles';

const MiniShoppingCartItem = ({ product }) => {
  const { getText } = useTranslations();
  const { isAuthenticated } = useUserStore();
  const [isOutOfRange, setIsOutOfRange] = useState(false);
  const history = useHistory();

  const handleQuantityChange = (quantity) => {
    quantity > product?.availableCouponsCount ? setIsOutOfRange(true) : setIsOutOfRange(false);

    Number(quantity) > 0 &&
      quantity < product?.availableCouponsCount &&
      updateProductQuantity(isAuthenticated, { ...product, quantity });
  };

  return (
    <div css={cartItemContainer}>
      <Image src={product.miniCartImage} alt="product" className="product-image" />
      <div className="cart-info-container">
        <div className="title-delete-container">
          <div
            role="button"
            className="product-name"
            tabIndex={0}
            onClick={() => history.push({ pathname: `/products/${product.itemId}`, state: product })}>
            <p>{getText(product.productTitle)}</p>
          </div>
          <Button
            type="link"
            small
            onClick={() => removeProduct(isAuthenticated, product.dealId ?? product.id)}
            className="delete-button">
            <Icon iconName="delete" size={20} />
          </Button>
        </div>
        <div className="quantity-price-container">
          <CounterInput
            isOutOfRange={isOutOfRange}
            value={product.quantity}
            onChange={(val) => handleQuantityChange(val)}
          />
          <ProductPrice product={product} showFinalPrice />
        </div>
      </div>
    </div>
  );
};

MiniShoppingCartItem.propTypes = {
  product: PropTypes.object,
};

export default MiniShoppingCartItem;
