import { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { isFunction, isNil } from '@veraio/core';
import { useLocations } from '@oneecosystem/dealshaker-core';
import { Button, Col, Dropdown, Form, Input, Modal, PhoneInput, Row, showApiError } from 'components';
import { useCountries } from 'stores';
import { createNewUserAddress } from 'services';
import { ReactComponent as DefaultCountryImage } from 'assets/images/default-country-image.svg';
import { addressFormContainer, countryContainer, countryImage, countryListLabel } from './styles';

const AddressModalForm = forwardRef((props, ref) => {
  const { onClose, onAddAddress } = props;
  const { getText } = useTranslations();
  const countriesStore = useCountries();
  const countries = useLocations((locationsState) => locationsState.countries);
  const modalRef = useRef();
  const formRef = useRef();

  useImperativeHandle(ref, () => ({
    open: () => modalRef.current?.open(),
    close: () => modalRef.current?.close(),
  }));

  const countryData =
    countriesStore?.length &&
    countries?.map((el) => ({
      ...countriesStore?.find((country) => country.name === el.name),
      ...el,
    }));

  const countryListOptions =
    countryData?.length &&
    countryData?.map((el) => ({
      id: el.id,
      label: (
        <div css={countryContainer}>
          {!isNil(el.flag) ? (
            <div>{el.flag}</div>
          ) : (
            <div css={countryImage}>
              <DefaultCountryImage />
            </div>
          )}
          <span css={countryListLabel}>{el.name}</span>
        </div>
      ),
    }));

  const handleSubmit = async (values) => {
    const data = { ...values, countryId: values?.countryId.id };

    const [, err] = await createNewUserAddress(data);
    if (err) return showApiError(err);

    formRef.current?.reset();
    onClose();
    isFunction(onAddAddress) && onAddAddress();
  };

  return (
    <Modal large closeIcon ref={modalRef} css={addressFormContainer}>
      <Form ref={formRef} className="form-container" onSubmit={handleSubmit}>
        <Row horizontalGap={32} className="shopping-cart-info-container">
          <Col sm={12} md={6} lg={6}>
            <Input
              small
              required
              formId="name"
              margin="0 0 1em"
              label={getText('yourName')}
              placeholder={getText('pleaseEnterYourFirstLastName')}
            />
          </Col>
          {/* <Col sm={12} md={6} lg={6}>
            <Input
              required
              small
              formId="email"
              margin="0 0 1em"
              label={getText('emailAddress')}
              placeholder={getText('emailAddress')}
              validate={inputValidation('required', 'email')}
            />
          </Col> */}
          <Col sm={12} md={6} lg={6}>
            <PhoneInput
              small
              required
              className="phone-input"
              formId="phoneNumber"
              margin="0 0 1em"
              label={getText('phoneNumber')}
              placeholder={getText('yourPhoneNumber')}
              codePlaceholder={getText('phoneCode')}
            />
          </Col>
          <Col sm={12} md={6} lg={6}>
            <Dropdown
              small
              required
              noClear
              withSearch
              formId="countryId"
              label={getText('country')}
              placeholder={getText('chooseCountry')}
              uniqueKey="id"
              displayKey="label"
              className="dropdown"
              options={countryListOptions}
            />
          </Col>
          <Col sm={12} md={6} lg={6}>
            <Input
              small
              formId="state"
              margin="0 0 1em"
              label={getText('stateProvince')}
              placeholder={getText('pleaseEnterYourStateProvince')}
            />
          </Col>
          <Col sm={12} md={6} lg={6}>
            <Input
              small
              required
              formId="city"
              margin="0 0 1em"
              label={getText('city')}
              placeholder={getText('pleaseEnterYourCity')}
            />
          </Col>
          <Col sm={12} md={6} lg={6}>
            <Input
              small
              required
              formId="street"
              margin="0 0 1em"
              label={getText('streetAddress')}
              placeholder={getText('pleaseEnterYourStreetAddress')}
            />
          </Col>
          <Col sm={12} md={6} lg={6}>
            <Input
              small
              required
              formId="postcode"
              margin="0 0 1em"
              label={getText('postcode')}
              placeholder={getText('pleaseEnterYourPostcode')}
            />
          </Col>
          <Col sm={12} md={12} lg={12}>
            {({ onSubmit }) => (
              <div className="action-container">
                <Button small type="info" className="apply-button" onClick={onClose}>
                  {getText('cancel')}
                </Button>
                <Button small className="apply-button" onClick={onSubmit}>
                  {getText('addAddress')}
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});

AddressModalForm.propTypes = {
  onClose: PropTypes.func,
  onAddAddress: PropTypes.func,
};

export default AddressModalForm;
