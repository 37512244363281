import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { sub } from 'exact-math';
import { useTranslations } from '@veraio/strank';
import { CRYPTO_CURRENCIES, useCurrencies, useLocations } from '@oneecosystem/dealshaker-core';
import { Image, PriceLabel } from 'components';
import { WALLET_TYPE, DEFAULT_CURRENCY } from 'enums';
import { walletCartContainer } from './styles';

const WalletCart = ({ wallet, oespWallet, linkTo, className }) => {
  const { getText } = useTranslations();
  const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);
  const geoLocation = useLocations((locationsState) => locationsState.geoLocation);
  const history = useHistory();

  const walletsMap = (walletType, balance) => {
    switch (true) {
      case walletType.toLowerCase() === WALLET_TYPE.COIN.toLowerCase():
        return {
          label: 'crypto',
          currency: geoLocation?.isCryptoRestricted ? CRYPTO_CURRENCIES.OESP : CRYPTO_CURRENCIES.ONE,
          balance: geoLocation?.isCryptoRestricted
            ? `${sub(oespWallet?.balance ?? 0, oespWallet?.blockedBalance ?? 0)?.toFixed(4)} ${
                CRYPTO_CURRENCIES.OESP.code
              }`
            : `${balance?.toFixed(4)} ${CRYPTO_CURRENCIES.ONE.code}`,
        };
      case walletType.toLowerCase() === WALLET_TYPE.CASH.toLowerCase():
        return {
          label: 'fiat',
          currency: null,
          balance: (
            <PriceLabel
              fiat={Number(balance)}
              fiatOriginCurrency={DEFAULT_CURRENCY.code}
              fiatDesiredCurrency={selectedCurrency?.code}
              fiatOnly={false}
            />
          ),
        };
      default:
        return null;
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      css={walletCartContainer(wallet?.walletType.toLowerCase())}
      onClick={() => linkTo && history.push(linkTo)}
      {...(className && { className })}>
      <Image src="https://oneecosystem.blob.core.windows.net/vita/apps/images/wallet-icon.svg" className="icon" />
      <div>
        <p className="wallet-title">{getText(`${walletsMap(wallet?.walletType.toLowerCase())?.label}Wallet`)}</p>
        <h5>
          {
            walletsMap(wallet?.walletType.toLowerCase(), sub(wallet?.balance ?? 0, wallet?.blockedBalance ?? 0))
              ?.balance
          }
        </h5>
      </div>
    </div>
  );
};

WalletCart.propTypes = {
  wallet: PropTypes.object,
  oespWallet: PropTypes.object,
  linkTo: PropTypes.string,
  className: PropTypes.string,
};

export default WalletCart;
