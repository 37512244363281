import queryString from 'query-string';
import { isNumber, pick } from '@veraio/core';

export const getAllMerchantDealsParams = (options) => {
  const { businessId } = options;

  return queryString.stringify(
    {
      ...pick(options, ['pageNumber', 'pageSize']),
      ...(isNumber(businessId) && { businessId }),
    },
    { arrayFormat: 'index' },
  );
};
