import { useEffect, useState } from 'react';
import { sub } from 'exact-math';
import { useTranslations } from '@veraio/strank';
import { isEmpty, isNil, setStorageValue, getStorageValue } from '@veraio/core';
import { useLocations } from '@oneecosystem/dealshaker-core';
import {
  AddressCart,
  Button,
  Col,
  Dropdown,
  EmptyShoppingCart,
  Icon,
  MiniShoppingCartItem,
  Row,
  showApiError,
  TotalPricesCard,
  WalletCart,
  useWindowSize,
} from 'components';
import { PAYMENTS_METHODS, ROUTES, USER_STATUS, WALLET_TYPE } from 'enums';
import { emptyShoppingCart, updateDeliveryAddress, updatePaymentMethods, useShoppingCart, useUserStore } from 'stores';
import { createOrder, getAccountWallets, getAllUserAddresses } from 'services';
import { fadeInAnimation } from 'styles';
import { errorContent, shoppingCartContainer } from './styles';

const storageKey = 'shopping-cart-summery';

const ShoppingCart = ({ history }) => {
  const shoppingCart = useShoppingCart();
  const user = useUserStore();
  const { isAuthenticated } = useUserStore();
  const { getText, language } = useTranslations();
  const geoLocation = useLocations((locationsState) => locationsState.geoLocation);
  const windowWidth = useWindowSize(window.innerWidth, window.innerHeight);
  const shoppingCartData = getStorageValue('shopping-cart');

  const cartItems = shoppingCart?.businesses?.flatMap((business) => business.items);
  const selectedDeliveryAddressId = cartItems?.length && cartItems[0].selectedUserAddressId;
  const dealShakerAccounts = user?.userShakerInfo?.accounts;
  const activeAccounts = user?.accounts?.filter((el) => el.status === USER_STATUS.Active);
  const isMobile = windowWidth.width < 767;

  const [isLoading, setIsLoading] = useState(false);
  const [allWallets, setAllWallets] = useState([]);
  const [values, setValues] = useState({
    userAddresses: null,
    selectedAccountId: activeAccounts?.length && activeAccounts[0]?.id,
    selectedUserAddressId: selectedDeliveryAddressId ?? user?.userShakerInfo?.userAddresses[0]?.id,
  });

  const wallets =
    allWallets &&
    allWallets?.filter(
      (el) =>
        el.walletType.toLowerCase() === WALLET_TYPE.COIN.toLowerCase() ||
        el.walletType.toLowerCase() === WALLET_TYPE.CASH.toLowerCase(),
    );
  const oespWallet = allWallets?.find((el) => el.walletType === WALLET_TYPE.OESP);
  const fiatPrice = shoppingCart.discountedTotalLocalPriceFiat ?? shoppingCart.totalLocalPriceFiat;
  const cryptoPrice = shoppingCart.discountedTotalPriceCrypto ?? shoppingCart.totalPriceCrypto;
  const hasPriceError =
    cryptoPrice >
      sub(
        wallets?.find((el) => el.walletType.toLowerCase() === WALLET_TYPE.COIN.toLowerCase())?.balance ?? 0,
        wallets?.find((el) => el.walletType.toLowerCase() === WALLET_TYPE.COIN.toLowerCase())?.blockedBalance ?? 0,
      ) ||
    fiatPrice >
      sub(
        wallets?.find((el) => el.walletType.toLowerCase() === WALLET_TYPE.CASH.toLowerCase())?.balance ?? 0,
        wallets?.find((el) => el.walletType.toLowerCase() === WALLET_TYPE.CASH.toLowerCase())?.blockedBalance ?? 0,
      );

  useEffect(() => {
    window.scrollTo(0, 0);
    values?.selectedAccountId && fetchWalletsByAccountId(values?.selectedAccountId);
    !isEmpty(user?.userShakerInfo) &&
      updatePaymentMethods({
        cryptoPaymentMethod: geoLocation?.isCryptoRestricted ? PAYMENTS_METHODS.oespWallet : PAYMENTS_METHODS.oneWallet,
        cashPaymentMethod: PAYMENTS_METHODS.cashWallet,
      });
  }, []);

  useEffect(() => {
    user?.userShakerInfo?.userAddresses && fetchUserAllAddresses();
  }, [user?.userShakerInfo?.userAddresses]);

  const fetchWalletsByAccountId = async (accountId) => {
    const [res, err] = await getAccountWallets(accountId);
    err ? showApiError(err) : setAllWallets(res);
  };

  const handleSelectAccount = (val) => {
    fetchWalletsByAccountId(val.id);
    setValues((prev) => ({ ...prev, selectedAccountId: val.id }));
  };

  const handleUpdateUserAddress = (selectedUserAddressId) => {
    setValues((prev) => ({ ...prev, selectedUserAddressId }));

    updateDeliveryAddress(
      isAuthenticated,
      shoppingCart?.businesses?.map((business) => ({
        ...business,
        items: business?.items?.map((item) => ({ ...item, selectedUserAddressId, selectedBusinessAddressId: null })),
      })),
      selectedUserAddressId,
    );
  };

  const fetchUserAllAddresses = async () => {
    const params = { pageNumber: 0, pageSize: 100 };
    const [res, err] = await getAllUserAddresses(params);
    err ? showApiError(err) : setValues((prev) => ({ ...prev, userAddresses: res?.items }));
  };

  const handleCreateOrder = async () => {
    setIsLoading(true);
    const accountId = dealShakerAccounts?.find((el) => el.externalId === values.selectedAccountId)?.id;
    const [res, err] = await createOrder(accountId, { languageCode: language?.code });

    if (err) {
      setIsLoading(false);
      return showApiError(err);
    }
    setIsLoading(false);

    const summeryData = {
      ...shoppingCartData,
      deliveryAddress: values?.selectedUserAddressId,
      accountId: values?.selectedAccountId,
      orderId: res[0],
    };

    setStorageValue(storageKey, summeryData);
    emptyShoppingCart();
    res && history.push(ROUTES.ShoppingCartSummery);
  };

  return (
    <div css={shoppingCartContainer}>
      <h3 className="header-title">{getText('shoppingCart')}</h3>

      {cartItems.length > 0 ? (
        <>
          <div className="address-cart-container">
            <h4 className="subtitle">{getText('address')}</h4>
            <AddressCart
              withAdd
              active={values?.selectedUserAddressId}
              addresses={values?.userAddresses ?? user?.userShakerInfo?.userAddresses}
              onChange={handleUpdateUserAddress}
              onAddAddress={fetchUserAllAddresses}
            />
          </div>

          <Row gap={isMobile ? 4 : 32} className="shopping-cart-info-container">
            <Col sm={12} md={12} lg={6}>
              <h4 className="subtitle">{getText('products')}</h4>

              <div className="items-container">
                <div className="mini-cart-items-container">
                  {cartItems?.map((product) => (
                    <MiniShoppingCartItem key={product.dealId ?? product.id} product={product} />
                  ))}
                </div>
              </div>
            </Col>

            <Col sm={12} md={12} lg={6}>
              <Dropdown
                noClear
                withSearch
                label={getText('account')}
                placeholder={getText('selectAccount')}
                uniqueKey="id"
                displayKey="displayName"
                className="accounts-dropdown"
                value={activeAccounts[0]}
                options={activeAccounts}
                onChange={handleSelectAccount}
              />

              {activeAccounts?.length === 0 && (
                <div className="error-container">
                  <Icon material iconName="error" className="error-icon" size={14} color="error" />
                  <p className="error-text">{getText('doNotHaveAccounts', { accountStatus: USER_STATUS.Active })}</p>
                </div>
              )}

              <div className="wallets-container">
                <p className="subtitle">{getText('yourBalance')}</p>
                <div className="wallet-cart-container">
                  {wallets?.map((el, ind) => (
                    <WalletCart key={ind} wallet={el} oespWallet={oespWallet} className="wallet-cart" />
                  ))}
                </div>
              </div>

              {hasPriceError && (
                <div css={[errorContent, fadeInAnimation()]}>
                  <Icon material iconName="error" className="error-icon" size={16} />
                  <span>{getText('notEnoughBalance')}</span>
                </div>
              )}

              <hr className="horizontal-line" />

              <h4 className="subtitle">{getText('price')}</h4>

              <TotalPricesCard shoppingCart={shoppingCart} />

              <Button
                disabled={
                  isNil(values?.selectedAccountId) || hasPriceError || isNil(values?.selectedUserAddressId) || isLoading
                }
                className="confirm-button"
                onClick={handleCreateOrder}>
                <Icon material iconName="lock" size={14} className="icon" />
                {getText('confirm')}
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <EmptyShoppingCart />
      )}
    </div>
  );
};

export default ShoppingCart;
