import { Spinner } from 'components';
import { mainContainer } from './styles';

const Loading = () => (
  <div css={mainContainer}>
    <Spinner size={65} loading />
  </div>
);

export default Loading;
