import { pick } from '@veraio/core';

export const addToShoppingCartBody = (productsList) =>
  productsList.map((el) => ({ dealId: el.id ?? el.dealId, quantity: el.quantity ?? 1 }));

export const updateShoppingCartProductQuantityBody = (product) => pick(product, ['dealId', 'quantity']);

export const updateShoppingCartDeliveryBody = (items) => ({
  items: items.map((el) => pick(el, ['dealId', 'selectedBusinessAddressId', 'selectedUserAddressId'])),
});
