import queryString from 'query-string';
import { getReq } from 'services/axios';
import { isArray } from '@veraio/core';
import { myProfileModel } from 'services';

export const getConfig = (values) => {
  const params = queryString.stringify({ key: values });

  return getReq(`${apiUrls.lifeApiUrl}/config/get?${params}`);
};

export const getOneLifeProfile = async () => {
  const [data, err] = await getReq(`${apiUrls.lifeApiUrl}/profile/v2/my`);
  return [data && myProfileModel(data), err];
};

export const getAccountWallets = (accountId) => {
  const params = queryString.stringify(
    { accountIds: isArray(accountId) ? accountId : [accountId] },
    { arrayFormat: 'comma' },
  );

  return getReq(`${apiUrls.lifeApiUrl}/wallets/v2/my?${params}`);
};
