import { orderBy } from 'lodash-es';
import { createStore, showApiError, useStore } from 'components';
import { ALL_PRODUCTS_MOCK_DATA } from 'enums';
import { getAllMerchantDeals } from 'services';

const getProductData = (productId) => ALL_PRODUCTS_MOCK_DATA.find((el) => el.itemId === productId);

const initProductsState = {
  cleaningGel: { ...getProductData(1) },
  fillerSerum: { ...getProductData(2) },
  eyeCream: { ...getProductData(3) },
  dayCream: { ...getProductData(4) },
  nightCream: { ...getProductData(5) },
  calmingAndHydratingMask: { ...getProductData(6) },
  metabolicMask: { ...getProductData(7) },
};

const productsStore = createStore(initProductsState);

export const initProductsStore = async () => {
  const params = {
    pageNumber: 0,
    pageSize: 100,
    businessId: merchantBusinessId,
  };

  const [dealsData, dealsError] = await getAllMerchantDeals(params);
  if (dealsError) return showApiError(dealsError);

  const data = dealsData?.items
    .map((el) => ({ ...el, ...ALL_PRODUCTS_MOCK_DATA.find((element) => element.dealId === el.id) }))
    ?.filter((el) => el.dealId);

  productsStore.setState(orderBy(data, 'id', 'asc'));
};

export const useProductsStore = (...args) => useStore(productsStore, ...args);
