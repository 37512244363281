import { theme } from 'styles';

export const dropDownContainer = (hasValidation, { disabled }) => ({
  position: 'relative',
  cursor: disabled ? 'not-allowed' : 'pointer',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginBottom: hasValidation ? 6 : 0,
});

export const dropDownSelect = (isOpened, isDisabled, hasError, small) => ({
  color: isDisabled ? theme.gray200 : theme.gray500,
  fontWeight: 400,
  fontSize: 13,
  borderBottom: `1px solid ${
    isOpened ? theme.black40 : hasError ? theme.error : isDisabled ? theme.gray100 : theme.black24
  }`,
  backgroundColor: hasError && theme.gray100,
  minHeight: small ? '3.6rem' : '4.8rem',
  padding: small ? '0.35em 0.85em' : '0.6em 0.8em',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const dropDownText = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const dropDownItemsCountBadge = (isSmall) => ({
  fontSize: isSmall ? '1.1rem' : '1.2rem',
  width: isSmall ? '1.7rem' : '2rem',
  height: isSmall ? '1.7rem' : '2rem',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 0.8em 0 0.2em',
  padding: 0,
});

export const dropDownOptionsCollapse = {
  borderBottom: `1px solid ${theme.gray100}`,
  '& .collapse-title': {
    display: 'flex',
    alignItems: 'center',
    '& .title': {
      marginRight: '0.5em',
    },
  },
};

export const dropDownOptionsContainer = (placement) => ({
  height: 'auto',
  maxHeight: 320,
  overflowY: 'auto',
  backgroundColor: theme.white,
  transformOrigin: placement === 'bottom' ? 'top' : 'bottom',
  transition: 'transform 400ms ease-in-out',
  cursor: 'default',
  boxShadow: '0 4px 4px rgba(0,0,0,0.3)',
  border: '1px solid #DDDDDD',
  borderRadius: '0.5rem',
});

export const dropDownOptionItem = (isSelected, small) => ({
  backgroundColor: isSelected ? theme.secondary : theme.white,
  color: isSelected ? theme.black : theme.gray400,
  display: 'inline-flex',
  alignItems: 'center',
  width: '100%',
  cursor: 'pointer',
  padding: small ? '0.6em' : '1.2rem',

  '&:hover': !isSelected && {
    color: theme.gray400,
    backgroundColor: theme.gray100,
  },
  '&:focus': {
    outline: 'none',
  },
  '& i': {
    marginRight: 8,
  },
});

export const dropDownGroupLabel = {
  margin: 0,
  padding: '1.2rem',
  cursor: 'default',
  color: theme.gray200,
  fontSize: 14,
};

export const rightIconsContainer = {
  display: 'flex',
  alignItems: 'center',
};

export const dropDownRightIcon = (isOpened) => ({
  fontSize: '1.3rem',
  transform: `rotate(${isOpened ? -180 : 0}deg)`,
  transition: 'transform 0.35s ease-out',
  cursor: 'pointer',
  display: 'inline-flex',
  color: theme.gray500,
});

export const dropDownDeleteIcon = {
  display: 'inline-flex',
  fontSize: '1.3rem',
  cursor: 'pointer',
  marginRight: 8,
  color: theme.gray200,

  '&:hover': {
    color: theme.gray500,
  },
};

export const dropDownClearAll = ({ small }) => ({
  position: 'sticky',
  bottom: 0,
  left: 1,
  right: 1,
  padding: small ? '0 0.6em' : '0 1.2rem',
  color: theme.error,
  backgroundColor: theme.white,
  display: 'flex',
  alignItems: 'center',
  height: small ? '3.6rem' : '4.8rem',
  cursor: 'pointer',
  borderTop: `1px solid ${theme.gray100}`,

  '&:hover': {
    backgroundColor: theme.gray100,
  },
});

export const dropdownSearch = {
  position: 'sticky',
  top: 0,
  zIndex: 1,
  '& .input': {
    borderRadius: 0,
    border: 'none',
    borderBottom: `1px solid ${theme.gray200}`,
  },
};

export const noResultsContainer = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1.6rem 0.8rem',
};

export const noResultsIcon = {
  backgroundColor: theme.info,
  borderRadius: '50%',
  padding: 8,
};

export const infiniteScroll = {
  padding: '1.6rem',
  display: 'flex',
  justifyContent: 'center',
};
