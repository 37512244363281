import { theme } from 'styles';

export const affiliateFromContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '4em 0',

  '.apply-button': {
    width: '100%',
    textTransform: 'uppercase',
  },

  '.affiliate-form-header': {
    width: '100%',
    backgroundColor: theme.primaryLight,
    height: 500,
    padding: '7.25em 3em',

    '.affiliate-form-header-info': {
      color: theme.white,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      '.header-title': {
        fontSize: 40,
        fontWeight: 400,
        textAlign: 'center',
        marginBottom: 12,
        maxWidth: 450,

        '@media (max-width: 992px)': {
          width: '100%',
        },

        '@media (max-width: 576px)': {
          textAlign: 'start',
          fontSize: 32,
        },
      },

      '.header-subtitle': {
        fontSize: 20,
        fontWeight: 300,
        maxWidth: 400,
        textAlign: 'center',
        marginBottom: 40,

        '@media (max-width: 992px)': {
          marginBottom: 12,
        },

        '@media (max-width: 767px)': {
          textAlign: 'left',
        },
      },
    },
  },

  '.form-container': {
    maxWidth: 500,
    backgroundColor: theme.white,
    padding: '60px 32px',
    border: `1px solid ${theme.black}`,
    borderRadius: 8,
    marginBottom: 40,
    marginTop: -250,
    zIndex: 10,

    '.input-label': {
      width: 150,
    },

    '.phone-input': {
      '.phone-dropdown': {
        '.input-container': {
          '.phone-dropdown': {
            '.select-dropdown': {
              backgroundColor: theme.white,
              border: 'none',
              borderBottom: `1px solid ${theme.black24}`,
            },
          },
        },
      },
    },

    '.dropdown': {
      '& > .select-dropdown': {
        border: 'none',
        borderBottom: `1px solid ${theme.black24}`,
        backgroundColor: theme.white,
      },
    },

    '.kyc-title': {
      fontSize: 20,
      textTransform: 'uppercase',
      color: theme.gray200,
      margin: '32px 0 16px',
    },

    '.upload-id-proof-container': {
      display: 'flex',
      flexDirection: 'row',

      '.upload-id-doc': {
        marginRight: 16,

        '@media (max-width: 576px)': {
          marginRight: 0,
        },
      },

      '@media (max-width: 576px)': {
        flexWrap: 'wrap',
      },
    },

    '.address-proof': {
      '.input-container': {
        '.address-proof': {
          '.label-container': {
            '.button': {
              '.upload-label': {
                maxWidth: 210,
              },
            },
          },
        },
      },
    },

    '.check-button': {
      textTransform: 'uppercase',
      fontSize: 20,
      marginBottom: 24,
    },

    '.affiliate-form-info': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      '.form-info-title': {
        paddingTop: 24,
        fontSize: 18,
        fontWeight: 300,
        textAlign: 'center',
        marginBottom: 12,
        color: theme.black,
      },
      '.form-info-description-container': {
        fontSize: 14,

        '.form-info-description': {
          color: theme.black56,
        },

        '.touch-text': {
          margin: '0 4px',
        },
      },
    },

    '@media (max-width: 576px)': {
      borderRadius: 0,
      border: 'none',
      borderBottom: `1px solid ${theme.gray200}`,
      marginBottom: 0,
    },
  },

  '.success-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 800,
    backgroundColor: theme.white,
    padding: 60,
    border: `1px solid ${theme.black}`,
    borderRadius: 8,
    margin: '40px 24px',

    '& > *': {
      textAlign: 'center',
      marginBottom: 12,
    },

    '.success-icon': {
      width: 129,
      height: 128,

      '@media (max-width: 576px)': {
        width: 81,
        height: 80,
      },
    },

    '.success-title': {
      fontSize: 48,
      fontWeight: 400,
      color: theme.black,

      '@media (max-width: 576px)': {
        fontSize: 40,
      },
    },

    '.success-subtitle': {
      fontSize: 20,
      fontWeight: 300,
      marginBottom: 32,
      color: theme.primaryLight,
    },

    '.not-receive-email': {
      fontSize: 24,
      fontWeight: 400,
      color: theme.black,
    },

    '.email-subtitle': {
      fontSize: 18,
      fontWeight: 300,
      marginBottom: 24,
      color: theme.black,
    },

    '@media (max-width: 767px)': {
      padding: 40,
      margin: '60px 24px',
    },

    '@media (max-width: 576px)': {
      padding: 40,
    },
  },
};

export const countryContainer = {
  display: 'flex',
  padding: 8,
};

export const countryListLabel = {
  marginLeft: 12,
};

export const countryImage = {
  marginRight: 12,
  width: 20,
  height: 20,
};
