import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { printPrice, useCurrencies } from '@oneecosystem/dealshaker-core';
import { Icon, Image, Button, PriceLabel, Skeleton, Tooltip } from 'components';
import { DEFAULT_CURRENCY } from 'enums';
import { addProduct, useUserStore } from 'stores';
import { productContainer, contentInfoTooltipContainer } from './styles';

const ProductSliderCart = ({ product }) => {
  const history = useHistory();
  const { getText } = useTranslations();
  const isAuthenticated = useUserStore((userState) => userState.isAuthenticated);
  const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);

  const convertedToLocalCurrencyPrice = (price) =>
    price && (
      <PriceLabel
        fiat={Number(price?.priceFiat)}
        fiatOriginCurrency={price?.currencyCode}
        fiatDesiredCurrency={DEFAULT_CURRENCY.code}
        fiatCrypto={price?.priceCrypto}
        percentage={price?.percentRatio}
        total={price?.discountedPrice ?? price?.price}
      />
    );

  return isEmpty(product) ? (
    <Skeleton width={'100%'} height={645} />
  ) : (
    <div css={productContainer}>
      <div className="product-container">
        <Image src={product.sliderImage} alt="product-image" className="product-image" />
        <div className="product-info-container">
          <p className="product-title">{getText(product.productTitle)}</p>
          <div className="price-icon-quantity-container">
            <p className="product-price">
              {printPrice({
                fiat: Number(product?.discountedPrice ?? product?.price),
                fiatOriginCurrency: product?.currencyCode,
                fiatDesiredCurrency: selectedCurrency?.code ?? DEFAULT_CURRENCY.code,
                total: product?.discountedPrice ?? product?.price,
              })}
            </p>
            <Tooltip
              content={convertedToLocalCurrencyPrice(product)}
              placement="bottom"
              noArrow
              contentClassName={contentInfoTooltipContainer}
              className="info-tooltip">
              <Icon iconName="info" size={16} className="icon" />
            </Tooltip>
            <p className="product-quantity">
              {product?.numberOfQuantity
                ? getText('quantityMl', { quantity: product.quantityMl, numberOfQuantity: product.numberOfQuantity })
                : getText('ml', { quantity: product.quantityMl })}
            </p>
          </div>
          <div className="action-container">
            <Button type="default" className="button-default" onClick={() => addProduct(isAuthenticated, product)}>
              {getText('buyNow')}
            </Button>
            <Button
              type="link"
              className="button-link"
              onClick={() => history.push({ pathname: `/products/${product.itemId}`, state: product })}>
              {getText('learnMore')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSliderCart;
