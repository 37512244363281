import { theme } from 'styles';

export const totalPriceContainer = {
  marginBottom: 40,

  '.price-container': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '12px 0',
    color: theme.gray800,
  },

  '.total-styles': {
    fontWeight: 600,
  },
};
