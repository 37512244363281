export const WALLET_TYPE = {
  COIN: 'coin',
  DSCP: 'dscp',
  COINSAFE: 'coinsafe',
  CASHSAFE: 'cashsafe',
  CASH: 'cash',
  TRADING: 'trading',
  TOKEN: 'token',
  BV: 'businessVolume',
  GAS: 'gas',
  RESERVECASH: 'reservecash',
  OESP: 'OESP',
};
