import { startCase } from 'lodash-es';
import { getText } from '@veraio/strank';
import { Button, Image } from 'components';
import { ROUTES } from 'enums';
import { discoverBeautyContainer } from './styles';

const DiscoverBeauty = () => (
  <div css={discoverBeautyContainer}>
    <div className="discover-beauty-first-col">
      <h3 className="discover-beauty-title">{startCase(getText('discoverTimelessBeauty'))}</h3>
      <p className="discover-beauty-subtitle">{getText('unveilSecretOfLiposomesAndZeolites')}</p>

      <Button type="secondary" className="action-button" linkTo={ROUTES.Products}>
        {getText('exploreProducts')}
      </Button>

      <Image
        src="https://oneecosystem.blob.core.windows.net/vita/apps/images/discover-valentines-first-image.jpg"
        alt="discover-day-night-cream"
        className="first-image"
      />
    </div>

    <div className="discover-beauty-second-col">
      <Image
        src="https://oneecosystem.blob.core.windows.net/vita/apps/images/discover-valentines-second-image.jpg"
        alt="discover-filler-cleaning-eye-cream"
        className="discover-beauty-second-image"
      />
      <Image
        src="https://oneecosystem.blob.core.windows.net/vita/apps/images/discover-valentines-third-image.jpg"
        alt="discover-masks"
        className="discover-beauty-third-image"
      />
    </div>
  </div>
);

export default DiscoverBeauty;
