import { theme } from 'styles';

export const homePageContainer = (isMobile) => ({
  width: '100vw',
  marginTop: '4em',

  '.video-container': {
    height: '100%',
    position: 'relative',

    '.unmute-icon': {
      position: 'absolute',
      bottom: 10,
      left: 10,
    },
  },

  '.upper-text': {
    textTransform: 'uppercase',
    fontWeight: 300,
    fontSize: 20,
    marginBottom: 8,

    '@media (max-width: 576px)': {
      textAlign: 'center',
    },
  },

  '.title': {
    fontWeight: 400,
    fontSize: 48,
    marginBottom: 24,
    color: theme.black,

    '@media (max-width: 992px)': {
      fontSize: 42,
    },

    '@media (max-width: 576px)': {
      fontSize: 40,
      textAlign: 'center',
    },
  },

  '.secondary-button': {
    textTransform: 'uppercase',
    backgroundColor: 'transparent',
    color: theme.gray500,
    border: `1px solid ${theme.grayLight}`,
    fontSize: 16,
    fontWeight: 400,

    '@media (max-width: 576px)': {
      width: '100%',
    },
  },

  '.crystal-image-container': {
    position: 'relative',
    zIndex: 10,
    height: 500,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '.crystal-image': {
      height: 500,
      width: '100vw',

      '@media (max-width: 992px)': {
        height: 660,
      },

      '@media (max-width: 767px)': {
        maxHeight: 600,
      },
    },

    '.text-over-crystal-container': {
      position: 'absolute',
      width: '100%',
      zIndex: 100,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '5em 2em',

      '.title-company': {
        fontWeight: 300,
        fontSize: 24,
        color: theme.black80,
        textTransform: 'uppercase',
        marginBottom: 16,
      },

      '.title-beauty': {
        fontWeight: 400,
        fontSize: 54,
        color: theme.black,
        marginBottom: 16,
        textAlign: 'center',

        '@media (max-width: 576px)': {
          fontSize: 40,
        },
      },

      '.description': {
        fontWeight: 300,
        fontSize: 22,
        maxWidth: 853,
        textAlign: 'center',
        marginBottom: 36,

        '@media (max-width: 576px)': {
          textAlign: 'start',
        },
      },
    },

    '@media (max-width: 767px)': {
      height: 600,
    },
  },

  '.from-nature-container': {
    backgroundColor: theme.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '6.8em 9.25em',

    '.product-slider': {
      width: '100%',
      height: '100%',
      borderBottom: isMobile ? 'none' : `1px solid ${theme.black10}`,

      '.react-multiple-carousel__arrow': {
        zIndex: 0,
      },
    },

    '.column-cart': {
      display: 'flex',
      width: '100%',
      marginBottom: 40,
    },

    '@media (max-width: 767px)': {
      padding: '6.8em 2em',
    },
  },

  '.about-container': {
    '.about-image': {
      width: '100%',
      height: '100%',
    },

    '.about-info': {
      backgroundColor: theme.secondary,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: 24,
    },

    '.description-about': {
      fontSize: 20,
      fontWeight: 300,
      color: theme.gray300,
      maxWidth: 530,
      marginBottom: 32,
    },

    '.about-secondary-button': {
      textTransform: 'uppercase',
      backgroundColor: 'transparent',
      color: theme.gray500,
      border: `1px solid ${theme.grayLight}`,
      fontSize: 16,
      fontWeight: 400,
      width: 'fit-content',

      '@media (max-width: 767px)': {
        width: '100%',
      },
    },
  },

  '.routine-steps-container': {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 48,
    marginBottom: 48,

    '@media (max-width: 576px)': {
      padding: 24,
      marginBottom: 0,
    },
  },

  '.secrets': {
    backgroundColor: theme.secondaryLighter,

    '.secrets-container': {
      padding: '3em 11.25em',

      '.title-secrets': {
        fontWeight: 400,
        fontSize: 48,
        marginBottom: 24,
        color: theme.black,
        maxWidth: 300,

        '@media (max-width: 576px)': {
          fontSize: 40,
        },
      },

      '.secrets-description-container': {
        display: 'flex',
        alignItems: 'center',

        '.description-secrets': {
          fontSize: 20,
          fontWeight: 300,
          color: theme.gray300,
          maxWidth: 580,
          marginBottom: 32,

          '@media (max-width: 992px)': {
            maxWidth: '100%',
          },
        },
      },

      '@media (max-width: 992px)': {
        padding: '3em',
      },

      '@media (max-width: 767px)': {
        padding: 24,
      },
    },

    '.secrets-button-container': {
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: 36,
      padding: '0 0  3em 3em',

      '@media (max-width: 767px)': {
        justifyContent: 'flex-start',
      },

      '@media (max-width: 576px)': {
        padding: 24,
      },
    },
  },
});

export const thumbnailImageStyles = (isDesktop, notTabletNotMobile, isTablet, isMobile, showVideo) => ({
  height: isDesktop
    ? 'calc(100vh - 60px)'
    : isMobile
    ? 'calc(100vh - 124px)'
    : notTabletNotMobile
    ? 'auto'
    : isTablet
    ? 'calc(100vh - 64px)'
    : '100%',
  display: showVideo ? 'none' : 'flex',
});
