import moment from 'moment';
import { isArray } from '@veraio/core';
import { getDynamicTranslation, getText } from '@veraio/strank';
import { ColorBatch } from 'components';
import { ORDER_STATUS_COLOR, ORDER_STATUS_INVERSE, PAYMENTS_METHODS_INVERSE } from 'enums';

export const ORDER_INFO = (orderDetails) => {
  return [
    {
      label: 'orderId',
      value: `# ${orderDetails.id}`,
    },
    {
      label: 'createdDate',
      value: moment(orderDetails.createdDate).format('DD.MM.YYYY, HH:MM'),
    },
    {
      label: 'status',
      value: (
        <ColorBatch type={ORDER_STATUS_COLOR[orderDetails?.statusId]?.color} className="color-batch">
          {getText(ORDER_STATUS_INVERSE[orderDetails?.statusId])}
        </ColorBatch>
      ),
    },
    {
      label: 'payment',
      value: `${getText(PAYMENTS_METHODS_INVERSE[orderDetails?.payment?.cashPaymentMethodId])} + ${getText(
        PAYMENTS_METHODS_INVERSE[orderDetails?.payment?.cryptoPaymentMethodId],
      )}`,
    },
  ];
};

export const USER_DELIVERY_INFO = (deliveryAddress) => [
  {
    label: 'names',
    value: isArray(deliveryAddress?.name) ? getDynamicTranslation(deliveryAddress?.name)?.name : deliveryAddress?.name,
  },
  {
    label: 'country',
    value: deliveryAddress?.country,
  },
  {
    label: 'streetAddress',
    value: deliveryAddress?.street,
  },
  {
    label: 'stateProvince',
    value: deliveryAddress?.state,
  },
  {
    label: 'city',
    value: deliveryAddress?.city,
  },
  {
    label: 'postcode',
    value: deliveryAddress?.postcode,
  },
  {
    label: 'phoneNumber',
    value: `+${deliveryAddress?.phoneNumber}`,
  },
];

export const USER_INFO = (lifeInfo, accountInfo) => [
  {
    label: 'names',
    value: `${lifeInfo?.firstName} ${lifeInfo?.lastName}`,
  },
  {
    label: 'emailAddress',
    value: lifeInfo?.email,
  },
  {
    label: 'accountId',
    value: `# ${accountInfo?.id}`,
  },
  {
    label: 'externalAccountId',
    value: `# ${accountInfo?.externalId}`,
  },
  {
    label: 'accountNickname',
    value: accountInfo?.name,
  },
];
