import { getReq, postReq } from 'services/axios';
import { getAllUserAddressesParams } from 'services';

const baseUrl = `${apiUrls.dealshakerPortalApiUrl}/api`;

export const getDealShakerProfile = () => getReq(`${baseUrl}/Users/Me`);

export const createNewUserAddress = (data) => postReq(`${baseUrl}/UserAddresses/Create`, data);

export const getAllUserAddresses = (options) =>
  getReq(`${baseUrl}/UserAddresses/List?${getAllUserAddressesParams(options)}`);
