import { ShoppingCart, ShoppingCartSummery } from 'screens/private';
import { ROUTES } from 'enums';

export const PrivateRoutes = {
  products: {
    internal: [
      {
        url: ROUTES.ShoppingCart,
        label: 'ShoppingCart',
        component: ShoppingCart,
      },
      {
        url: ROUTES.ShoppingCartSummery,
        label: 'ShoppingCartSummery',
        component: ShoppingCartSummery,
      },
    ],
  },
};
