import queryString from 'query-string';
import { pick } from '@veraio/core';

export const getAllUserAddressesParams = (options = {}) => {
  return queryString.stringify(
    {
      ...pick(options, ['pageNumber', 'pageSize']),
    },
    { arrayFormat: 'index' },
  );
};
