export const addressFormContainer = {
  '.phone-input': {
    padding: 0,
  },

  '.action-container': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
};

export const countryContainer = {
  display: 'flex',
};

export const countryListLabel = {
  marginLeft: 12,
};

export const countryImage = {
  width: 12,
  height: 12,
};
